import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./NavBar.css";
import { bubble as Menu } from 'react-burger-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faInstagram, faPinterest  } from '@fortawesome/free-brands-svg-icons'
import {isMobile} from "react-device-detect"

function NavBar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const closeMenu = () => {
    setClick(false);
  }  

  return (
    <>
      <nav className="navbar">

        {!isMobile && <Link to="/" className="navbar-logo" onClick={closeMenu} style={{paddingRight: '30px'}}>
          WhyNotCrafts 
        </Link>    }
        
        <div className="hamburger" onClick={handleClick}> 
            <Menu
            >
                <a className="menu-item" href="/">
                    Home
                </a>
                <a className="menu-item" href="/sunnyflower">
                    Sunny Flower
                </a>
                <a className="menu-item" href="/sophiesgarden">
                    Sophie's Garden
                </a>
                <a className="menu-item" href="/gallery">
                    Gallery
                </a>
                {/* <a className="menu-item" href="/instructions">
                    Instructions
                </a> */}
                <a className="menu-item" href="http://www.whynotcrafts.com">
                    Etsy Store
                </a>
            </Menu>
        </div>

        <a href="https://www.instagram.com/whynotcraftstirecovers" style={{paddingRight: '30px', cursor: 'pointer'}}>
            <FontAwesomeIcon icon={faInstagram} size='2x'/>
        </a>
        <a href="https://www.pinterest.com/whynotcrafts/" style={{paddingRight: '30px', cursor: 'pointer'}}>
            <FontAwesomeIcon icon={faPinterest} size='2x'/>
        </a>        
      </nav>
    </>
  );
}

export default NavBar;