import React, { useState } from 'react'
import { Container, Row, Col } from 'react-grid-system';
import Amethyst from './../images/Yarn/Amethyst.jpg'
import Aran from './../images/Yarn/Aran.jpg'
import ArubaSea from './../images/Yarn/ArubaSea.jpg'
import BabyPink from './../images/Yarn/BabyPink.jpg'
import Black from './../images/Yarn/Black.jpg'
import Blue from './../images/Yarn/Blue.jpg'
import BrightYellow from './../images/Yarn/BrightYellow.jpg'
import Buff from './../images/Yarn/Buff.jpg'
import Burgundy from './../images/Yarn/Burgundy.jpg'
import CafeLatte from './../images/Yarn/CafeLatte.jpg'
import Carrot from './../images/Yarn/Carrot.jpg'
import Charcoal from './../images/Yarn/Charcoal.jpg'
import CherryRed from './../images/Yarn/CherryRed.jpg'
import Claret from './../images/Yarn/Claret.jpg'
import Coffee from './../images/Yarn/Coffee.jpg'
import Coral from './../images/Yarn/Coral.jpg'
import Cornmeal from './../images/Yarn/Cornmeal.jpg'
import CountryBlue from './../images/Yarn/CountryBlue.jpg'
import DarkOrchid from './../images/Yarn/DarkOrchid.jpg'
import DelftBlue from './../images/Yarn/DelftBlue.jpg'
import Denium from './../images/Yarn/Denium.jpg'
import DustyGrey from './../images/Yarn/DustyGrey.jpg'
import Flame from './../images/Yarn/Flame.jpg'
import Flamingo from './../images/Yarn/Flamingo.jpg'
import FrostyGreen from './../images/Yarn/FrostyGreen.jpg'
import Gold from './../images/Yarn/Gold.jpg'
import Grenadine from './../images/Yarn/Grenadine.jpg'
import HeatherGrey from './../images/Yarn/HeatherGrey.jpg'
import HotRed from './../images/Yarn/HotRed.jpg'
import HunterGreen from './../images/Yarn/HunterGreen.jpg'
import Jade from './../images/Yarn/Jade.jpg'
import Lavender from './../images/Yarn/Lavender.jpg'
import Lemon from './../images/Yarn/Lemon.jpg'
import LightBlue from './../images/Yarn/LightBlue.jpg'
import LightGrey from './../images/Yarn/LightGrey.jpg'
import LightJasmine from './../images/Yarn/LightJasmine.jpg'
import LightPeriwinkle from './../images/Yarn/LightPeriwinkle.jpg'
import LightRaspberry from './../images/Yarn/LightRaspberry.jpg'
import LightSage from './../images/Yarn/LightSage.jpg'
import MediumPurple from './../images/Yarn/MediumPurple.jpg'
import MediumThyme from './../images/Yarn/MediumThyme.jpg'
import Minty from './../images/Yarn/Minty.jpg'
import Mulberry from './../images/Yarn/Mulberry.jpg'
import Oatmeal from './../images/Yarn/Oatmeal.jpg'
import Orchid from './../images/Yarn/Orchid.jpg'
import PaddyGreen from './../images/Yarn/PaddyGreen.jpg'
import PalePlum from './../images/Yarn/PalePlum.jpg'
import PaleYellow from './../images/Yarn/PaleYellow.jpg'
import PerfectPink from './../images/Yarn/PerfectPink.jpg'
import Persimmon from './../images/Yarn/Persimmon.jpg'
import Pool from './../images/Yarn/Pool.jpg'
import PrettyNPink from './../images/Yarn/PrettyNPink.jpg'
import Pumpkin from './../images/Yarn/Pumpkin.jpg'
import RealTeal from './../images/Yarn/RealTeal.jpg'
import Rosy from './../images/Yarn/Rosy.jpg'
import Royal from './../images/Yarn/Royal.jpg'
import Saffron from './../images/Yarn/Saffron.jpg'
import ShockingPink from './../images/Yarn/ShockingPink.jpg'
import SoftNavy from './../images/Yarn/SoftNavy.jpg'
import SpringGreen from './../images/Yarn/SpringGreen.jpg'
import TeaLeaf from './../images/Yarn/TeaLeaf.jpg'
import Turqua from './../images/Yarn/Turqua.jpg'
import White from './../images/Yarn/White.jpg'

function YarnColors(props) {
    const [selectedColor, setSelectedColor] = useState("White")
    const colors = [
        { name: "White", img: White },
        { name: "Aran", img: Aran },
        { name: "Buff", img: Buff },
        { name: "PaleYellow", img: PaleYellow },
        { name: "Lemon", img: Lemon },
        { name: "Cornmeal", img: Cornmeal },
        { name: "BrightYellow", img: BrightYellow },
        { name: "Gold", img: Gold },
        { name: "Saffron", img: Saffron },
        { name: "FrostyGreen", img: FrostyGreen },
        { name: "Jade", img: Jade },
        { name: "LightSage", img: LightSage },
        { name: "PaddyGreen", img: PaddyGreen },
        { name: "HunterGreen", img: HunterGreen },
        { name: "MediumThyme", img: MediumThyme },
        { name: "SpringGreen", img: SpringGreen },
        { name: "TeaLeaf", img: TeaLeaf },
        { name: "RealTeal", img: RealTeal },
        { name: "Minty", img: Minty },
        { name: "ArubaSea", img: ArubaSea },
        { name: "LightBlue", img: LightBlue },
        { name: "CountryBlue", img: CountryBlue },
        { name: "Turqua", img: Turqua },
        { name: "LightPeriwinkle", img: LightPeriwinkle },
        { name: "DelftBlue", img: DelftBlue },
        { name: "Blue", img: Blue },
        { name: "Royal", img: Royal },
        { name: "Pool", img: Pool },
        { name: "Denium", img: Denium },
        { name: "SoftNavy", img: SoftNavy },
        { name: "PalePlum", img: PalePlum},
        { name: "Orchid", img: Orchid },
        { name: "MediumPurple", img: MediumPurple },
        { name: "Lavender", img: Lavender },
        { name: "DarkOrchid", img: DarkOrchid },
        { name: "Amethyst", img: Amethyst },
        { name: "LightJasmine", img: LightJasmine },
        { name: "BabyPink", img: BabyPink },
        { name: "PerfectPink", img: PerfectPink },
        { name: "LightRaspberry", img: LightRaspberry },
        { name: "Flamingo", img: Flamingo },
        { name: "Grenadine", img: Grenadine },
        { name: "ShockingPink", img: ShockingPink },
        { name: "PrettyNPink", img: PrettyNPink },
        { name: "Persimmon", img: Persimmon },
        { name: "Rosy", img: Rosy },
        { name: "Pumpkin", img: Pumpkin },
        { name: "Coral", img: Coral },
        { name: "Carrot", img: Carrot},
        { name: "Flame", img: Flame },
        { name: "HotRed", img: HotRed },
        { name: "CherryRed", img: CherryRed },
        { name: "Burgundy", img: Burgundy },
        { name: "Claret", img: Claret },
        { name: "Mulberry", img: Mulberry },
        { name: "Oatmeal", img: Oatmeal },
        { name: "LightGrey", img: LightGrey },
        { name: "DustyGrey", img: DustyGrey },
        { name: "HeatherGrey", img: HeatherGrey },
        { name: "Charcoal", img: Charcoal },
        { name: "CafeLatte", img: CafeLatte },
        { name: "Coffee", img: Coffee },
        { name: "Black", img: Black }]

    function handleChange(color) {
        setSelectedColor(color)
        props.changeColor(color)
    }

    const groupedColors = [];
    while (colors.length > 0) {
        const chunk = colors.splice(0, props.columnCount);
        groupedColors.push(chunk);
    }

    return (
        <Container >
            {groupedColors.map((someColors, index) => (
                <Row style={{padding: "0px"}} key={index}>
                    <Col style={{padding: "0px"}} key={index} sm={12} width="100%">
                        {someColors.map((color, index) => (                        
                                (selectedColor === color.name) ? (
                                <img key={index} src={color.img} alt={color.name} width="60" height="60" onClick={() => handleChange(color.name)}
                                    style={{border: "3px solid black"}}/>) :
                                (<img key={index} src={color.img} width="60" height="60" onClick={() => handleChange(color.name)}/>)
                            
                        ))}
                    </Col> 
                </Row>
            ))}
        </Container>
    );
}

export default YarnColors;