import React, { useState, useEffect, } from 'react'
import { send } from 'emailjs-com';
import "./SendDesign.css";
import { Container, Row, Col } from 'react-grid-system';
import { faL } from '@fortawesome/free-solid-svg-icons';

export const SendDesign = (props) => {
    const [toSend, setToSend] = useState({
        from_name: '',
        from_email: '',
        ordered: '',
        notes: '',
        colors: ''
      });
      const [sendFinishedMessage, setSendFinishedMessage] = useState("")
      const [error, setError] = useState("")
      const [sendClicked, setSendClicked] = useState(false)

      useEffect(() => {
        setSendFinishedMessage("")
        setError("")
        setSendClicked(false)
      }, [props.fillColors]);

      useEffect(() => {
        if (toSend.from_email == "" && sendClicked){
          setError("Please enter email address")
        } else {
          setError("")
        }
      }, [toSend]);
    
      const onSubmit = (e) => {      
      };
    
      const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
      };

      function handleCancel () {
        setSendFinishedMessage("")
        setError("")
        setSendClicked(false)
        props.onClose()
      }

      function handleSend () {
        setSendClicked(true)
        if (toSend.from_email == ""){
            setError("Please enter email address")
        } else {
          setError("")

          var colorString = "";          
          props.fillColors.map((c, index) => (            
            colorString += "Row " + (index + 1) + ": " + c.substring(5).slice(0, -1) + "\n"           
          ));
          toSend.colors = colorString

          send(
            'service_dv84jy8',
            'template_d07w94f',
            toSend,
            'PO1T2zo8XvqQa7MR1'
          )
            .then((response) => {
              console.log('SUCCESS!', response.status, response.text);
              setSendFinishedMessage("Email Sent to WhyNotCrafts.")
            })
            .catch((err) => {
              console.log('FAILED...', err);
              setSendFinishedMessage("Email failed to send. Try again!")
            });
        }
      }
    
      return (
        <div>
          {sendFinishedMessage == "" ? (            
          <Container style={{width: "100%", height: "100%", margin:"0px", padding: "0px",
                          background: "linear-gradient(90deg, rgb(2, 202, 176) 0%, rgb(202, 245, 245) 100%)"}}>
            <Row style={{padding:"5px"}}>
              <Col sm={12}>
                <p>Send Colors to WhyNotCrafts?</p>
              </Col>
            </Row>
            <Row style={{paddingLeft:"15px"}}>            
              <Col sm={6}>                
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <label htmlFor="name">Name: </label>
                      <input className="form-control" id="name" 
                      type='text'
                      name='from_name'
                      placeholder=''
                      value={toSend.from_name}
                      onChange={handleChange} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email address: </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name='from_email'
                        placeholder=''
                        value={toSend.from_email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Have you already Ordered?</label>
                      <input
                        type="text"
                        className="form-control"
                        id="ordered"
                        name='ordered'
                        placeholder=''
                        value={toSend.ordered}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Notes: </label>
                      <textarea id="notes" value={toSend.notes}
                        onChange={handleChange} name="notes" rows="6" cols="20"/>
                    </div>                    
                    <button className="bn30" type="submit" hidden>Send</button>
                    
                  </form>                
                </Col>
                <Col sm={6}>
                  {props.fillColors.map((c, index) => (
                      <div key={index} style={{ textAlign:'left' }}>
                        Row {index + 1}: {c.substring(5).slice(0, -1)}
                      </div>
                  ))} 
                </Col>
                
            </Row>
          <Row style={{ display:"flex", alignItems:"center", paddingLeft: "40px", paddingTop: "20px", width:"400px" }}>
              <Col sm={12}>
              <div >
                      <button className="bn30" id="sendButton" onClick={handleSend} type="button">Send</button>                      
                      <button className="bn30" id="sendButton" onClick={handleCancel} type="button">Cancel</button>
                    </div>
              </Col>
            </Row>
            {error != "" && <Row><Col sm={12}><p>{error}</p></Col></Row>}
            {error == "" && <Row><Col sm={12}><p>&nbsp;  </p></Col></Row>}
          </Container>
          ) : (
            <Container style={{width: "100%", height: "100%", margin:"0px", padding: "0px",
                background: "linear-gradient(90deg, rgb(2, 202, 176) 0%, rgb(202, 245, 245) 100%)"}}>
              <Row style={{padding:"5px"}}>
                <Col sm={12}>
                <p >{sendFinishedMessage}</p>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      )
}