import React from "react";
import Gallery from "react-photo-gallery";
import { photos } from "../images/photos";

const PhotoGallery = () => { 
    return (   
        <div>
            <Gallery photos={photos} style={{ height: 100 }} />
        </div>
    )
}

export default PhotoGallery;