import React, { useState } from 'react'
import { CirclePicker } from 'react-color';

export const HexColors = [
    { name: "White", hex: "#ffffff" },
    { name: "Aran", hex: "#F8FAD2" },
    { name: "Buff", hex: "#FDE6AE" },
    { name: "PaleYellow", hex: "#F7FAB2" },
    { name: "Lemon", hex: "#F5FA95" },
    { name: "Cornmeal", hex: "#F5F972" },
    { name: "BrightYellow", hex: "yellow" },
    { name: "Gold", hex: "#FFD000" },
    { name: "Saffron", hex: "#FFDF00" },
    { name: "FrostyGreen", hex: "#D6F6BA" },
    { name: "Jade", hex: "#039c7c" },
    { name: "LightSage", hex: "#4FBD7A" },
    { name: "PaddyGreen", hex: "#1fa824" },
    { name: "HunterGreen", hex: "#0F2E02" },
    { name: "MediumThyme", hex: "#0C7438" },
    { name: "SpringGreen", hex: "#47FF00" },
    { name: "TeaLeaf", hex: "#62C13D" },
    { name: "RealTeal", hex: "#008080" },
    { name: "Minty", hex: "#00FFDA" },
    { name: "ArubaSea", hex: "#0AF1D0" },
    { name: "LightBlue", hex: "#D0EFEE" },
    { name: "CountryBlue", hex: "#2BCBC7" },
    { name: "Turqua", hex: "#26deff" },
    { name: "LightPeriwinkle", hex: "#9EB3F3" },
    { name: "DelftBlue", hex: "#5B73BC" },
    { name: "Blue", hex: "#0000FF" },
    { name: "Royal", hex: "#3D01C8" },
    { name: "Pool", hex: "#0369aa" },
    { name: "Denim", hex: "#615D91" },
    { name: "SoftNavy", hex: "#160E84" },
    { name: "PalePlum", hex: "#FBCFF7" },
    { name: "Orchid", hex: "#FCA7F5" },
    { name: "MediumPurple", hex: "#DF45C3" },
    { name: "Lavender", hex: "#9C7094" },
    { name: "Dark Orchid", hex: "#AC3CA3" },
    { name: "Amethyst", hex: "#a117f2" },
    { name: "LightJasmine", hex: "#EBC6E8" },
    { name: "BabyPink", hex: "#ffc1cc" },
    { name: "PerfectPink", hex: "#ff91af" },
    { name: "LightRaspberry", hex: "#FD9798" },
    { name: "Flamingo", hex: "#FB7C58" },
    { name: "Grenadine", hex: "#ff007f" },
    { name: "ShockingPink", hex: "#F1027C" },
    { name: "PrettyNPink", hex: "#ff1493" },
    { name: "Persimmon", hex: "#f88379" },
    { name: "Rosy", hex: "#FDCDCE" },
    { name: "Pumpkin", hex: "#ffa500" },
    { name: "Coral", hex: "#ff4f00" },
    { name: "Carrot", hex: "#f77f00" },
    { name: "Flame", hex: "#ff4501" },
    { name: "HotRed", hex: "red" },
    { name: "CherryRed", hex: "#eb0000" },
    { name: "Burgundy", hex: "#c20000" },
    { name: "Claret", hex: "#750000" },
    { name: "Mulberry", hex: "#872657" },
    { name: "Oatmeal", hex: "#FCE3B6" },
    { name: "LightGrey", hex: "#dcdcdc" },
    { name: "DustyGrey", hex: "#b3b3b3" },
    { name: "HeatherGrey", hex: "#738276" },
    { name: "Charcoal", hex: "#36454f" },
    { name: "CafeLatte", hex: "#8b4513" },
    { name: "Coffee", hex: "#3D1C04" },
    { name: "Black", hex: "#000000" }
]

function Colors(props) {
    const colors = HexColors.map(color => color.hex.toString());
    const [selectedColor, setSelectedColor] = useState('#ffffff')

    function handleChange(color) {
        setSelectedColor(color.hex.toString())
        props.changeColor(color.hex.toString())
    }

    return (
        <CirclePicker color={selectedColor} colors={colors} onChangeComplete={handleChange} width="100%"/>
    );
}

export default Colors;