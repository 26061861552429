import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Carousel from './carousel/Carousel';
import sunnyfloweroutline from './../images/sunnyfloweroutline.jpg';
import sophiesgardenoutline from './../images/sophiesgardenoutline.jpg';
import {isMobile} from "react-device-detect"

const Main = () => { 
    return (   
        <div>
            {/* {!isMobile && <Carousel/>} */}
            <div>
                <p>Sunny Flower</p>
                <Link to="/sunnyflower">
                {isMobile ? 
                    <img src={sunnyfloweroutline} style={{width:"90%"}} /> :
                    <img src={sunnyfloweroutline} />
                }
                </Link>
            </div>
            <div>
                <p>Sophie's Garden</p>
                <Link to="/sophiesgarden">
                {isMobile ? 
                    <img src={sophiesgardenoutline} style={{width:"90%"}} /> :
                    <img src={sophiesgardenoutline} />
                }
                </Link>
            </div>
        </div>
    )
}

export default Main;