export const colorNames = [
    "White", 
    "Aran",
    "Buff", 
    "PaleYellow", 
    "Lemon",
    "Cornmeal",
    "BrightYellow",
    "Gold", 
    "Saffron", 
    "FrostyGreen", 
    "Jade", ,
    "LightSage", 
    "PaddyGreen", 
    "HunterGreen", 
    "MediumThyme", 
    "SpringGreen",
    "TeaLeaf", 
    "RealTeal", 
    "Minty", 
    "ArubaSea", 
    "LightBlue", 
    "CountryBlue",
    "Turqua", 
    "LightPeriwinkle", 
    "DelftBlue", 
    "Blue", ,
    "Royal", 
    "Pool", 
    "Denium", 
    "Orchid", 
    "MediumPurple", 
    "Lavender", 
    "DarkOrchid", 
    "Amethyst",
    "LightJasmine", 
    "BabyPink",
    "PerfectPink", 
    "LightRaspberry",
    "Flamingo", 
    "Grenadine", 
    "ShockingPink", 
    "PrettyNPink", 
    "Persimmon", 
    "Rosy", 
    "Pumpkin", 
    "Coral", 
    "Carrot", 
    "Flame", 
    "HotRed",
    "CherryRed", 
    "Burgundy", 
    "Claret", 
    "Mulberry", 
    "Oatmeal", 
    "LightGrey",,
    "DustyGrey", 
    "HeatherGrey", 
    "Charcoal", 
    "CafeLatte",
    "Coffee", 
    "Black"]