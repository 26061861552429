export const photos = [
    {
      src: "https://i.etsystatic.com/5354550/r/il/e74d99/1650863771/il_794xN.1650863771_5fxd.jpg",
      width: 800,
      height: 599
    },
    {
      src: "https://i.etsystatic.com/5354550/r/il/d9dfd4/1326306864/il_794xN.1326306864_3thd.jpg",
      width: 800,
      height: 599
    },
    {
      src: "https://i.etsystatic.com/5354550/r/il/4753dc/3004768340/il_794xN.3004768340_kqir.jpg",
      width: 800,
      height: 599
    },
    {
      src: "https://i.etsystatic.com/5354550/r/il/10e92e/2131366437/il_794xN.2131366437_3bqt.jpg",
      width: 800,
      height: 599
    },
    {
      src: "https://i.etsystatic.com/5354550/r/il/c5e686/2412899255/il_794xN.2412899255_p4l2.jpg",
      width: 800,
      height: 599
    },
    {
      src: "https://i.etsystatic.com/5354550/r/il/b68c2b/2810681684/il_794xN.2810681684_n1n3.jpg",
      width: 800,
      height: 599
    },
    {
      src: "https://i.etsystatic.com/5354550/r/il/17aa2c/2426093552/il_794xN.2426093552_anlo.jpg",
      width: 800,
      height: 599
    },
    {
      src: "https://i.etsystatic.com/5354550/r/il/090ab2/1795971146/il_794xN.1795971146_638m.jpg",
      width: 800,
      height: 599
    },
    {
      src: "https://i.etsystatic.com/5354550/r/il/3d34af/1989937590/il_794xN.1989937590_l28n.jpg",
      width: 800,
      height: 599
    },
    {
      src: "https://i.etsystatic.com/5354550/r/il/57c92d/2928300205/il_794xN.2928300205_bwnw.jpg",
      width: 800,
      height: 599
    },
    {
      src: "https://i.etsystatic.com/5354550/r/il/d13fdc/1610486713/il_794xN.1610486713_s16y.jpg",
      width: 800,
      height: 599
    },
    {
      src: "https://i.etsystatic.com/5354550/r/il/57c92d/2928300205/il_794xN.2928300205_bwnw.jpg",
      width: 800,
      height: 599
    },
    {
      src: "https://i.etsystatic.com/5354550/r/il/d4277f/2637357041/il_794xN.2637357041_o9pc.jpg",
      width: 800,
      height: 599
    },
    {
      src: "https://i.etsystatic.com/5354550/r/il/bdd09a/2305339906/il_794xN.2305339906_gljy.jpg",
      width: 800,
      height: 599
    },
    {
      src: "https://i.etsystatic.com/5354550/r/il/fb6dca/3190120237/il_794xN.3190120237_iysv.jpg",
      width: 800,
      height: 599
    }
  ];
  