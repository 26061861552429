import React, { useEffect, useState, createRef } from 'react'
import SophiesGarden from './designs/SophiesGarden'
import { Container, Row, Col } from 'react-grid-system';
import SunnyFlower from './designs/SunnyFlower'
import "./GenericDesign.css";
import YarnColors from './YarnColors';
import {isMobile} from "react-device-detect"
import {colorNames} from './ColorNames'
import {HexColors} from './Colors'
import { SendDesign } from './SendDesign';
//import Modal from "react-modal";
import Modal from 'react-awesome-modal';

const GenericDesign = (props) => {
    const [fillColors, setFillColors] = useState(Array(props.numOfColors).fill('url(#White)'))
    const [currentColor, setCurrentColor] = useState('White')
    const [hexFillColors, setHexFillColors] = useState(Array(props.numOfColors).fill('#ffffff'))
    const [displayYarnColors, setDisplayYarnColors] = useState(true)
    const [showSendDialog, setShowSendDialog] = useState(false)

    const onFillColor = (i) => {
        let newFillColors = fillColors.slice(0)
        newFillColors[i] = `url(#${currentColor})` 
        setFillColors(newFillColors)

        let newHexFillColors = hexFillColors.slice(0)
        newHexFillColors[i] = HexColors.find(x=> x.name == currentColor).hex.toString() 
        setHexFillColors(newHexFillColors)
    }
    
    function determineDesign(){
        if (displayYarnColors) {
            switch (props.designName) {
                case "SophiesGarden":
                    return <SophiesGarden fillColors={fillColors} onFill={onFillColor} />
                case "SunnyFlower":
                    return <SunnyFlower fillColors={fillColors} onFill={onFillColor} />
            }
        } else {
            switch (props.designName) {
                case "SophiesGarden":
                    return <SophiesGarden fillColors={hexFillColors} onFill={onFillColor} />
                case "SunnyFlower":
                    return <SunnyFlower fillColors={hexFillColors} onFill={onFillColor} />
            }
        }
    }

    function determineDialog () {
        switch (props.designName){
            case "SunnyFlower":
                return (<Modal visible={showSendDialog} width="400" height="500" effect="fadeInUp" onClickAway={handleCloseSaveDialog}>
                    <div>
                        <SendDesign fillColors={fillColors} onClose={handleCloseSaveDialog}/>
                    </div>
                </Modal>)
            case "SophiesGarden":
                return (<Modal visible={showSendDialog} width="400" height="650" effect="fadeInUp" onClickAway={handleCloseSaveDialog}>
                    <div>
                        <SendDesign fillColors={fillColors} onClose={handleCloseSaveDialog}/>
                    </div>
                </Modal>)
        }
    }

    function handleReset (){
        let newFillColors = fillColors.slice(0)
        newFillColors.map((color, index) => (
            newFillColors[index] = `url(#White)` 
        ))
        setFillColors(newFillColors)

        let newHexFillColors = hexFillColors.slice(0)
        newHexFillColors.map((color, index) => (
            newHexFillColors[index] = "#ffffff" 
        ))
        setHexFillColors(newHexFillColors)
    }

    function randomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

    function handleRandom (){
        let newFillColors = fillColors.slice(0)
        let newHexFillColors = hexFillColors.slice(0)
        newFillColors.map((color, index) => { 
            let randomColor = colorNames[randomInteger(0, 62)]

            while (!randomColor || !HexColors.find(x => x.name == randomColor)){
                randomColor = colorNames[randomInteger(0, 62)]
            }
            
            newFillColors[index] = `url(#${randomColor})` 
            newHexFillColors[index] = HexColors.find(x => x.name == randomColor).hex.toString() 
         } )
        setFillColors(newFillColors)
        setHexFillColors(newHexFillColors)
    }

    function handleSwitch () {
        setDisplayYarnColors(!displayYarnColors)
    }

    function handleSave(){
        setShowSendDialog(true)
    }

    function handleCloseSaveDialog () {
        setShowSendDialog(false)
    }

    return (  
        <div>
            <Container fluid style={{ marginLeft: '10px', marginTop: '0px', width: '1610px' }}>
                
                {isMobile ? (
                    <div>
                        <Row>
                            <Col sm={12} >
                                <div style={{  backgroundColor: "rgb(2, 202, 176)", color: "white",
                                        display: "flex", alignItems: "left", padding: "20px", width:"700px" }}>
                                    {currentColor.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <div style={{ backgroundColor: "rgb(2, 202, 176)",
                                    display:"flex", alignItems:"left", padding:"20px", width:"700px" }}>
                                    <YarnColors changeColor={setCurrentColor} columnCount={9}/>
                                </div>
                                <div style={{ backgroundColor: "rgb(2, 202, 176)", color: "white",
                                    display:"flex", alignItems:"center", padding:"5px", width:"700px"  }}>
                                Actual product color may vary from the images shown. Every monitor or mobile display has a different capability to display colors, and every individual may see these colors differently.
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <div style={{ backgroundColor: "rgb(2, 202, 176)",
                                    display:"flex", alignItems:"left", padding:"20px", width:"700px" }}>
                                    <button className="bn30" onClick={handleReset}>Reset</button>
                                    <button className="bn30" onClick={handleRandom}>Random</button>
                                    <button className="bn30" onClick={handleSave}>Save</button>
                                    {/* <button className="bn30" onClick={handleSwitch}>Switch</button> */}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} style={{ display:"flex", alignItems:"left"}}>
                                {determineDesign(props.designName)}
                            </Col>
                            <Col sm={2}>
                            {fillColors.map((c, index) => (
                                <div key={index} style={{ textAlign:'left' }}>
                                Row {index + 1}: {c.substring(5).slice(0, -1).match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}
                                </div>
                            ))} 
                            </Col>
                        </Row>
                    </div>
                ):(
                    <div>
                        <Row>
                            <Col sm={2}>
                                <div style={{ backgroundColor: "rgb(2, 202, 176)",
                                    display:"flex", alignItems:"center", padding:"5px" }}>
                                    <button className="bn30" onClick={handleReset}>Reset</button>
                                </div>
                                <div style={{ backgroundColor: "rgb(2, 202, 176)",
                                    display:"flex", alignItems:"center", padding:"5px" }}>
                                    <button className="bn30" onClick={handleRandom}>Random</button>
                                </div>
                                <div style={{ backgroundColor: "rgb(2, 202, 176)",
                                    display:"flex", alignItems:"center", padding:"5px" }}>
                                    <button className="bn30" onClick={handleSave}>Save</button>
                                </div>
                                {/* <div style={{ backgroundColor: "rgb(2, 202, 176)",
                                    display:"flex", alignItems:"center", padding:"5px" }}>
                                    <button className="bn30" onClick={handleSwitch}>Switch</button>
                                </div> */}
                                <div style={{  backgroundColor: "rgb(2, 202, 176)", color: "white",
                                        display: "flex", alignItems: "left", padding: "20px" }}>
                                    {currentColor.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}
                                </div>
                                <div style={{ backgroundColor: "rgb(2, 202, 176)",
                                    display:"flex", alignItems:"center", padding:"5px" }}>
                                    {<YarnColors changeColor={setCurrentColor} columnCount={3}/>}
                                </div>
                                <div style={{ backgroundColor: "rgb(2, 202, 176)", color: "white",
                                    display:"flex", alignItems:"center", padding:"5px" }}>
                                Actual product color may vary from the images shown. Every monitor or mobile display has a different capability to display colors, and every individual may see these colors differently.
                                </div>
                            </Col>
                            <Col sm={8}>
                                {determineDesign()}
                            </Col>
                            <Col sm={2}>
                                {fillColors.map((c, index) => (
                                    <div key={index} style={{ textAlign:'left' }}>
                                    Row {index + 1}: {c.substring(5).slice(0, -1).match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}
                                    </div>
                                ))} 
                            </Col>
                        </Row>
                    </div>
                )}
            </Container>

            {determineDialog()}            
        </div>
    )
}

export default GenericDesign;