import React from 'react'
import './App.css'
import Main from './components/Main';
import NavBar from './components/nav/NavBar';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GenericDesign from './components/GenericDesign';
import PhotoGallery from './components/PhotoGallery';
import Instructions from './components/Instructions';

const App = () => {    

    return (
        <Router>
            <div className="App" id="outer-container">
                <NavBar pageWrapId={'page-wrap'} outerContainerId={'outer-container'}  />
               <div id="page-wrap"></div>
                <Routes>
                    <Route path="/" caseSensitive={false} element={<Main />}/>
                    <Route path="/sunnyflower" caseSensitive={false} 
                        element={<GenericDesign numOfColors={15} designName="SunnyFlower" />}/>
                    <Route path="/sophiesgarden" caseSensitive={false} 
                        element={<GenericDesign numOfColors={22} designName="SophiesGarden" />}/>
                    <Route path="/gallery" caseSensitive={false} 
                        element={<PhotoGallery />}/>
                    {/* <Route path="/instructions" caseSensitive={false} 
                        element={<Instructions />}/> */}
                </Routes>
            </div>
        </Router>
    )
}

export default App;